<template>
  <div class="main-header">
    <div class="header-snb">
      <nav class="snb">
        <ul class="snb-list">
          <li v-for="(tab, index) in view.tabs" class="snb-item" :class="{'is-active': view.current === tab.key}" :key="index">
            <button class="tab" @click="view.current = tab.key; changeDiv(tab.key);">{{ tab.name }}</button>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</template>
<script>

import {capabilityReqSubjPopupSetup} from '@/assets/js/modules/capability/capability-setup';

export default {
  name: "MobileCapabilityReqSubjRoute",
  props:{
    divKey: String,
  },
  emits: ['update:div-change'],
  setup: capabilityReqSubjPopupSetup
}
</script>