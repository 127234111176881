<template>
  <div class="kb" id="kb">
    <!-- begin::kb-header -->
    <header class="kb-header" id="kb-header">
      <div class="header-component">
        <!-- header-util -->
        <div class="header-column header-util left">
          <div class="util util-back">
            <a href="javascript:" class="util-actions util-actions-back" @click="goBef">
              <i class="icon-history-back"></i>
            </a>
          </div>
        </div>
        <!-- header-title -->
        <div class="header-column header-title">
          직무필수과정 [{{ divKey }}] 교과목
        </div>
        <!-- //header-title -->
      </div>
    </header>
    <!-- end::kb-header -->
    <!-- begin::kb-main -->
    <main class="kb-main" id="kb-mypage">

      <MobileCapabilityReqSubjRoute :div-key="divKey" @divchange="divKeyChange" />
      <!-- main-content -->
      <div class="main-content min-component">
        <div class="mypage-body">
          <!-- content-section:연수이력 -->
          <article class="content-section pt-0">
            <div class="text-item-container">
                <div class="text-item" v-for="(course, index) in renderItems" :key="index">
                  <div class="item-row row-contents">
                    <div class="item-column">
                      <p class="item-subtitle">{{ course.courseDivNm }}</p>
                      <h5 class="item-title">{{ course.crseNm }}</h5>
                      <div class="item-meta">
                        <span class="text">{{ course.mediaCateNm.split(">")[0] }}</span>
                        <span class="text">{{ course.orgnzNm }}</span>
                      </div>
                      <div v-if="course.memo!=null" class="item-subtitle" style="margin-top:4px; margin-bottom: 1px;">* {{ course.memo }}</div>
                    </div>
                  </div>
                  <div class="item-row row-buttons">
                    <button class="kb-btn kb-btn-outline" @click="applyTrain(course.crseMstSn)"><span class="text">수강신청</span></button>
                  </div>
                </div>
            </div>
          </article>
        </div>
      </div>
    </main>
  </div>
</template>

<script>

import MobileCapabilityReqSubjRoute from '@/components/capability/mobile/MobileCapabilityReqSubjRoute';
import {computed, reactive} from 'vue';
import {useRoute, useRouter} from "vue-router";
import {useStore} from "vuex";

export default {
  name: "MobileCapabilityReqSubjPopup",
  components: {MobileCapabilityReqSubjRoute},
  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();

    const renderItems = computed(() => {
      return store.state.my.AbilityReqSubjList.filter( x => x.courseDivCd==view.current);
    });


    const divKey = computed(()=>{return route.params.courseKey});

    const view = reactive({current:""});
    view.current = divKey.value=="공통" ? "공통A" : "직무필수-선택A";

    const divKeyChange = (key) =>{
      view.current = key;
    };

    const applyTrain = (code) => {
      router.push({name: 'ApplyTrain', query : {'crseMstSn': code}});
    }

    const goBef = () => {
      //router.push({name: 'MyPageMain'});
      history.back()
    }

    return {
      MobileCapabilityReqSubjRoute,
      renderItems,
      goBef,
      applyTrain,
      divKey,
      divKeyChange,
      view
    }
  }
}
</script>
